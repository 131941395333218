import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import ButtonFieldInput from "../buttonField/Button";
import crossicon from "../../../Images/Icons/cross.svg";
import TextFieldInput from "../FormFields/TextFieldInput";
import getLocalStorageData from "../../../utils/getLocalStorageData";
import LoginCard from "../LoginCard";

const CheckExistUserModal = (props: any) => {
  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle className={`!py-5`}>
          <div className={`flex items-center justify-between h-2`}>
            <p className={`text-xl text-black font-medium`}>{props?.title}</p>
            <div className={`cursor-pointer`} onClick={props.onClose}>
              <img src={crossicon} alt="crossicon" height={15} width={15} />
            </div>
          </div>
        </DialogTitle>
        {/* <form onSubmit={props.emailVerifyForm.handleSubmit}> */}
          <DialogContent>
            <LoginCard
              title={`${props.cardTitle ? props.cardTitle : "Begin Your Journey Here"}`}
              description={`${props.cardDescription ? props.cardDescription : "Become a part of the solar revolution. Access your account to customize your solar net preferences."}`}
            >
              <form className="grid grid-cols-1 gap-4" onSubmit={props.emailVerifyForm.handleSubmit}>
                <TextFieldInput
                  fullWidth={true}
                  style={{
                    boxShadow: "inset 0px 4px 8px rgba(0, 0, 0, 0.05)",
                  }}
                  textnewclass={`bg-gn-white-10 rounded-[8px] w-[100%]`}
                  placeholder={`Enter Email`}
                  type={`text`}
                  textinputname={`email`}
                  id={`email`}
                  onChange={props.emailVerifyForm.handleChange}
                  onBlur={props.emailVerifyForm.handleBlur}
                  value={props.emailVerifyForm.values.email}
                  error={
                    props.emailVerifyForm.touched.email &&
                    Boolean(props.emailVerifyForm.errors.email)
                  }
                  errText={
                    props.emailVerifyForm.touched.email &&
                    props.emailVerifyForm.errors.email
                  }
                  clickEnter={props.emailVerifyForm.handleSubmit}
                  // disabled={getLocalStorageData("email") ? true : false}
                />

                <ButtonFieldInput
                  type="submit"
                  name="Continue"
                  loading={props.loader}
                  buttonextracls={`w-full !bg-[#2C71F6]/[0.9] !hover:bg-[#2C71F6] duration-300 transition-colors !text-white !py-3 rounded-lg font-[RobotoSemiBold]`}
                />

              </form>
            </LoginCard>
           
          </DialogContent>
        {/* </form> */}
      </Dialog>
    </div>
  );
};

export default CheckExistUserModal;
