/* eslint-disable react-hooks/exhaustive-deps */
// import img from "next/image";
import React, { useCallback, useEffect, useState } from "react";
// import logo from "../../Images/logo/Golf_net_logo.png";
import logo from "../../Images/logo/SN_Logo_02.png";
import logoBlack from "../../Images/logo/SN_Logo_BG_white_BW.png";
import hamburgerMenu from "../../../images/landingPage/header/hambergurMenu.png";
// import HamburgerMenu from "./hamburgerMenu/HamburgerMenu";
import darkHambergurMenu from "../../../images/landingPage/header/darkHambergurMenu.png";
import { ClickAwayListener } from "@mui/material";
import getLocalStorageData from "../../utils/getLocalStorageData";
import crossIcon from "../../Images/Icons/cross.svg";
// import { UserDetailsHooks } from "../../../container/admin/users/hooks";
import { isAuthorised } from "../../utils/commonFunc";
import { doGetApiCall } from "../../utils/ApiConfig";
import styles from "./header.module.css";
// import NavLink from "next/link";
import usericon from "../../Images/admin/usericon.svg";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { navigateToGn } from "../../utils/navigateToGn";
import { useJwt } from "react-jwt";
import help_icon from "../../Images/help_icon.svg";
import { fetchHelpContent, handleModal } from '../../redux/helpModalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosHelpCircleOutline } from "react-icons/io";
import { GoHome } from "react-icons/go";
import { FaUserLarge } from "react-icons/fa6";
import { FaCartShopping } from "react-icons/fa6";
import { RiDraftFill } from "react-icons/ri";
import { BsFillShieldLockFill } from "react-icons/bs";
import white_logo from "../../Images/logo/SolarNets_White_logo.png"



const Header = (props: any) => {
  let userDetails = getLocalStorageData("user");
  const dispatch = useDispatch();
  let token = getLocalStorageData("token");
  let userId = getLocalStorageData("userId");
  let quoteId = getLocalStorageData("quoteId");
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [openHamburger, setOpenHamburger] = useState(false);
  const [headBack, setHeadBack] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const { isExpired } = useJwt(token);
  let user = getLocalStorageData("user");
  let userAuth = getLocalStorageData("token");
  let role = getLocalStorageData("role");

  const { headerHelpContent } = useSelector((state: any) => state?.helpModalReducer)


  const openHamburgerMenu = () => {
    setOpenHamburger(true);
  };
  const closeHamburgerMenu = () => {
    setOpenHamburger(false);
  };

  const handleNavigation = (e: any) => {
    if (typeof window !== "undefined") {
      const window = e.currentTarget;
      if (window?.scrollY !== 0) {
        setHeadBack(true);
      } else {
        setHeadBack(false);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleNavigation);
      return () => {
        window.removeEventListener("scroll", handleNavigation);
      };
    }
  }, [handleNavigation]);

  /**
   * @uplNiladri
   * @description switch back to the default user functionality
   */
  // const getBackToMainUser = () => {
  //   dispatch({ type: "GET_ALL_ORDERS", payload: [] })
  //   // localStorage.setItem('token', getLocalStorageData('logedUserToken'))
  //   let data = {
  //     url: `${process.env.REACT_APP_PUBLIC_apiurl}/users/${getLocalStorageData('logedUserId')}`
  //   }
  //   doGetApiCall(data)
  //     .then((res: any) => {
  //       if (!res.error) {
  //         localStorage.setItem('user', JSON.stringify(res.result))
  //         localStorage.removeItem('logedUserToken')
  //         localStorage.removeItem('logedUserId')
  //         localStorage.removeItem('logedUserRole')
  //         window.location.replace(`${process.env.REACT_APP_PUBLIC_GN_URL}/admin/users`);
  //       }
  //       else {
  //         console.error(res)
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.error(err)
  //     })
  // }

  const getBackToMainUser = async () => {
    const gtToken = localStorage.getItem('token');
    let orderId = localStorage.getItem('orderId') || ""
    await localStorage.clear();
    if (orderId) {
      window.location.replace(navigateToGn(`admin/order/orderdetails/${orderId}?index=1`))
    }
    else {
      window.location.replace(navigateToGn(`admin/users?userType=admin&token=${gtToken}`));
    }

  };

  const goToLogin = () => {
    navigate(`${process.env.REACT_APP_PUBLIC_GN_URL}login`, { replace: true });
  };

  const logoutClick = () => {
    localStorage.clear();
    dispatch({ type: "RESET_STATE" });
    window.location.replace(navigateToGn("login?action=logout"));
  };

  const goToMyAccount = () => {
    navigate("/myaccount");
  };

  const goToMyDraft = () => {
    navigate("/mydraft");
  };

  const goToMyProfile = () => {
    navigate("/main/myprofile");
  };

  const goToQuote = async () => {
    if (
      userAuth &&
      userAuth.length > 0 &&
      !isExpired &&
      user &&
      Object.keys(user)?.length > 0
    ) {
      if (user.address) {
        await getUserDetails()
          .then(async () => {
            user = await getLocalStorageData("user");
            console.log(user?.quoteId, "user goToQuote #");
            if (user?.quoteId && user?.quoteId?.length > 0) {
              console.log(
                "if (user?.quoteId && user?.quoteId?.length > 0) part #"
              );
              if (
                user?.betaVersion &&
                (window.location.hostname === "dev.solargolfnet.com" ||
                  window.location.hostname === "test.solargolfnet.com" ||
                  window.location.hostname === "localhost")
              ) {
                let url = `${process.env.NEXT_PUBLIC_GN_CLIENT_URL}/?userId=${user?._id}`;
                if (userAuth) {
                  url = `${process.env.NEXT_PUBLIC_GN_CLIENT_URL}/?userId=${user?._id}&token=${userAuth}`;
                }
                navigate(url);
              } else {
                navigate(
                  `/main/quote/addquote?quoteId=${user?.quoteId}&index=0`
                );
              }
            } else {
              navigate(`/main/quote/addquote?index=0`);
            }
          })
          .catch((error) => {
            console.error(error, "getUserDetails error #");
          });
      } else {
        navigate("/main/quote/addaddress");
      }
    } else {
      navigate("/main/quote/adduser");
    }
  };

  // Get User details API call to update User Quote ID
  const getUserDetails = async () => {
    let userId = getLocalStorageData("userId")
      ? getLocalStorageData("userId")
      : getLocalStorageData("user")?._id;
    if (userId) {
      let data = {
        url: `${process.env.NEXT_PUBLIC_apiurl}/users/${userId}`,
      };
      await doGetApiCall(data).then((res: any) => {
        if (res.error) {
          navigate(`/login`);
        } else {
          console.log(res.result, "getUserDetails #");
          localStorage.setItem("user", JSON.stringify(res.result));
        }
      });
    }
  };

  const headerLink = `hover:text-[#0E3052] text-[#0E3052] px-[15px] xl:px-[24px] py-[8px] ${styles.underline}`;

  return (
    // <ClickAwayListener onClickAway={() => closeHamburgerMenu()}>
    <div className={`relative`}>
      <div
        style={{ boxShadow: `${headBack ? "0px 0 19px 0 #dddddd" : "none"}` }}
        className={`${props.headerExtraClass
          } fixed top-0 m-auto flex h-14 w-full items-center justify-between px-[2%] py-4 ${location.pathname?.match("/quote/addquote") ||
            location.pathname?.match("quote/netsupport") ||
            location.pathname?.match("quote/addshippingaddr") ||
            location.pathname?.match("quote/invoice") ||
            location.pathname?.match("quote/payment") ||
            location.pathname?.match("quote/editor") ||
            location.pathname?.match("quote/applyoffer")
            ? "bg-[#2C71F6]"
            : "bg-[#2C71F6]"
          } z-50 duration-500`}
      >
        <NavLink to={navigateToGn("")}>
          <div className={`${props.headerTextandLogoExtraClass}`}>
            {/* <a href={'/'}> */}
            <img
              src={
                location.pathname?.match("quote/addquote") ||
                  location.pathname?.match("quote/netsupport") ||
                  location.pathname?.match("quote/addshippingaddr") ||
                  location.pathname?.match("quote/invoice") ||
                  location.pathname?.match("/quote/payment") ||
                  location.pathname?.match("/quote/editor") ||
                  location.pathname?.match("quote/applyoffer")
                  ? white_logo
                  : white_logo
              }
              height={160}
              width={220}
              alt="Logo"
            />
            {/* </a> */}
          </div>
        </NavLink>

        <h1 className="text-2xl font-medium text-white">Net Designer</h1>

        <div className={` relative hidden items-center gap-2 lg:flex`}>
          {/* <div className="relative flex cursor-pointer items-center justify-between rounded-r-2xl bg-[#2c9ddb] px-1 text-white" onClick={() => { dispatch(handleModal({ payload: true })) }}>
            <img
              className="absolute -left-6 h-9 w-9 rounded-full border-2 border-white"
              src={help_icon}
              alt="icon"
            />
            <p className="mx-3 font-semibold">Help</p>
          </div> */}

          <h1
            onClick={() => { dispatch(handleModal({ payload: true })) }}
            className="font-medium flex items-center gap-1 text-[15px] cursor-pointer text-white">
            <IoIosHelpCircleOutline className="text-[20px]" />
            Help
          </h1>

          <div
            className="flex items-center gap-2"
            onClick={() => {
              dispatch({ type: "SELECTED_DIAGRAM_DATA", payload: {} });
            }}
          // className={`flex flex-wrap items-center justify-center md:justify-start ${styles.afterEffect
          //   } ${headBack ? `text-gn-black-500 ` : `${`text-gn-black-500`} `
          //   }  cursor-pointer text-center font-[SansRegular] text-base font-normal md:text-left`}
          >
            <NavLink
              data-testid="Footer-link-2"
              to={navigateToGn("")}
            >
              {/* <p className={headerLink}>Home</p> */}
              <h1
                className="font-medium text-white flex items-center gap-1 text-[15px] cursor-pointer ml-4">
                <GoHome className="text-[20px]" />
                Home
              </h1>

            </NavLink>
            {/* <NavLink to={navigateToGn('howitworks', searchParams.get('userId'), searchParams.get('quoteId'), searchParams.get('index'))} >
              <p className={headerLink}>How It Works</p>
            </NavLink>
            <NavLink data-testid='Footer-link-4' to={navigateToGn('#Specification', searchParams.get('userId'), searchParams.get('quoteId'), searchParams.get('index'))} >
              <p className={headerLink}>Specification</p>
            </NavLink>
            <NavLink data-testid='Footer-link-7' to={navigateToGn('#ContactUs', searchParams.get('userId'), searchParams.get('quoteId'), searchParams.get('index'))} >
              <p className={headerLink}>Contact Us</p>
            </NavLink>
            <NavLink data-testid='Footer-link-5' to={navigateToGn('gallery', searchParams.get('userId'), searchParams.get('quoteId'), searchParams.get('index'))} >
              <p className={headerLink}>Gallery</p>
            </NavLink>
            <NavLink data-testid='Footer-link-8' to={navigateToGn('faq', searchParams.get('userId'), searchParams.get('quoteId'), searchParams.get('index'))} >
              <p className={headerLink}>FAQ</p>
            </NavLink> */}

            {/* {!isExpired && <NavLink data-testid='Footer-link-8' to={navigateToGn('myaccount', searchParams.get('userId'), searchParams.get('quoteId'), searchParams.get('index'), token)} >
              <p className={` ${`hover:text-[#FF5C00]`} px-[15px] xl:px-[24px] py-[8px] text-white ${styles.underline}`}>My Order</p>
            </NavLink>
            }

            {!isExpired && <NavLink data-testid='Footer-link-8' to={navigateToGn('mydraft', searchParams.get('userId'), searchParams.get('quoteId'), searchParams.get('index'), token)} >
              <p className={` ${`hover:text-[#FF5C00]`} px-[15px] xl:px-[24px] py-[8px] text-white ${styles.underline}`}>My Draft</p>
            </NavLink>
            } */}

            {/* {userDetails?.role === 'admin' ? null : (token && <NavLink href={`/myaccount`} onClick={goToMyAccount} >
                <p className={`cursor-pointer ${router.asPath === "/myaccount" ? `text-[#FF5C00] hover:text-[#FF5C00] ` : `hover:text-[#FF5C00]`} px-[15px] xl:px-[24px] py-[8px] ${styles.underline}`} >My Order</p>
              </NavLink>)} */}
            {/* <NavLink to={navigateToGn('',searchParams.get('userId'),searchParams.get('quoteId'))} >
                <p className={`cursor-pointertext-[#FF5C00] text-white hover:text-[#FF5C00] px-[15px] xl:px-[24px] py-[8px] ${styles.underline}`} >Go to SGN</p>
              </NavLink> */}

            {/* {role === "admin" && token && (
              <NavLink
                data-testid="Footer-link-8"
                to={navigateToGn("/admin/users", "", "", "", token)}
              >
                <p className="text-white font-medium text-[12px] px-4 mx-3 border-[1px] border-white py-2 rounded-md">Back to Admin</p>
              </NavLink>
            )} */}

            {/* {userDetails?.role === "admin"
              ? null
              : token && (
                <p className={`px-4 py-[8px] text-black`}>
                  {userDetails?.name}
                </p>
              )} */}
          </div>

          {token && (
            <div className="relative">
              {openAuth && (
                <span
                  className={`absolute top-[3.5rem] w-full border-b-[3px] border-solid border-[#FF5C00]`}
                ></span>
              )}
              <div
                className={`w-9 h-9 bg-[#fff]/[0.2] text-white font-medium flex justify-center items-center rounded-md ml-3 cursor-pointer text-[15px]`}
                onClick={() => setOpenAuth(!openAuth)}
              >
                {user?.name ? user?.name[0] : role === 'admin' ? "Ad" : "Un"}
              </div>
            </div>
          )}

          {/* <img src={usericon} alt={`usericon`}
            onClick={() => setOpenAuth(!openAuth)}
            className={`cursor-pointer`}
          /> */}

          {token
            ? openAuth && (
              <ClickAwayListener onClickAway={() => setOpenAuth(false)}>
                <div
                  className={`${headBack ||
                    location.pathname?.match("/gallery") ||
                    location.pathname?.match("/faq") ||
                    location.pathname?.match("/myaccount") ||
                    location.pathname?.match("/mydraft") ||
                    location.pathname?.match("/main/myprofile") ||
                    location.pathname?.match("/main/quote/addquote") ||
                    location.pathname?.match("/main/quote/netsupport") ||
                    location.pathname?.match("/main/quote/addshippingaddr") ||
                    location.pathname?.match("/main/quote/invoice") ||
                    location.pathname?.match("/main/quote/payment") ||
                    location.pathname?.match("quote/applyoffer")
                    ? "top-[3.85rem] bg-white text-[#18181890] backdrop-blur-[5px]"
                    : "top-[2.5rem] bg-white text-[#18181890] backdrop-blur-[5px]"
                    } ${(location.pathname?.match("/myaccount") ||
                      location.pathname?.match("/main/myprofile")) &&
                    `top-[3.45rem]`
                    } ${location.pathname?.match("/mydraft")
                      ? `-right-[3rem]`
                      : (location.pathname?.match("/myaccount") ||
                        location.pathname?.match("/main/myprofile")) &&
                      `right-0`
                    }  absolute right-0 z-50  flex w-[250px] flex-col rounded-[15px] shadow-xl bg-white overflow-hidden`}
                >
                  {userDetails?.role === "admin" || role === "admin" || isAuthorised()
                    ? null
                    : token && (
                      <NavLink
                        to={navigateToGn(
                          "main/myprofile",
                          searchParams.get("userId") ?? userId,
                          searchParams.get("quoteId") ?? quoteId,
                          searchParams.get("index"),
                          token
                        )}
                      >
                        <h1 className="w-full cursor-pointer font-[InterMedium] text-[14px] flex items-center gap-4 
                        hover:bg-[#EDEBFE] px-5 py-3"><FaUserLarge className="text-[18px]" />My Profile</h1>
                      </NavLink>
                    )}

                  {role === "admin" && token &&
                    (
                      <div onClick={() => getBackToMainUser()}>
                        <h1 className="w-full cursor-pointer font-[InterMedium] text-[14px] flex items-center gap-4 
                        hover:bg-[#EDEBFE] px-5 py-3"><BsFillShieldLockFill className="text-[18px]" />Back to Admin</h1>
                      </div>
                    )}


                  {userDetails?.role === "admin" || role === "admin"
                    ? null
                    : token && (
                      <NavLink
                        data-testid="Footer-link-8"
                        to={navigateToGn(
                          "myaccount",
                          searchParams.get("userId") ?? userId,
                          searchParams.get("quoteId") ?? quoteId,
                          searchParams.get("index"),
                          token
                        )}
                      >
                        <h1 className="w-full cursor-pointer font-[InterMedium] text-[14px] flex items-center gap-4 
                        hover:bg-[#EDEBFE] px-5 py-3"><FaCartShopping className="text-[18px]" />My Order</h1>
                      </NavLink>
                    )}
                  {userDetails?.role === "admin" || role === "admin"
                    ? null
                    : token && (
                      <NavLink
                        data-testid="Footer-link-8"
                        to={navigateToGn(
                          "mydraft",
                          searchParams.get("userId") ?? userId,
                          searchParams.get("quoteId") ?? quoteId,
                          searchParams.get("index"),
                          token
                        )}
                      >
                        <h1 className="w-full cursor-pointer font-[InterMedium] text-[14px] flex items-center gap-4 
                        hover:bg-[#EDEBFE] px-5 py-3"><RiDraftFill className="text-[18px]" />My Draft</h1>
                      </NavLink>
                    )}
                  {
                    !isExpired ? (
                      // <NavLink to={navigateToGn('login?action=logout')} >
                      <h1 onClick={() => logoutClick()} className="w-full cursor-pointer font-[InterMedium] text-[14px] flex items-center gap-4 
                       hover:bg-[#EDEBFE] px-5 py-3"><FaUserLarge className="text-[18px]" />Log Out</h1>
                    ) : (
                      // </NavLink>
                      // <NavLink to={navigateToGn('login?action=logout')} >
                      <h1 onClick={() => logoutClick()} className="w-full cursor-pointer font-[InterMedium] text-[14px] flex items-center gap-4 
                       hover:bg-[#EDEBFE] px-5 py-3"><FaUserLarge className="text-[18px]" />Log In</h1>
                    )
                    // </NavLink>
                  }
                </div>
              </ClickAwayListener>
            )
            : null}
        </div>
        {/* this commented lines for hambergur */}
        <div
          className={`visible cursor-pointer lg:hidden ${props.hambergurExtraClass}`}
          onClick={openHamburgerMenu}
        >
          {/* {
              location.pathname?.match '/gallery' || location.pathname?.match '/gallery/posts/[slug]' || location.pathname?.match '/faq'  || location.pathname?.match '/faq/posts/[slug]' || location.pathname?.match '/main/quote/adduser' || location.pathname?.match '/main/quote/addaddress' || location.pathname?.match '/main/quote/addquote' || location.pathname?.match '/main/quote/netsupport' || location.pathname?.match '/myaccount' || location.pathname?.match '/main/quote/invoice' || location.pathname?.match '/main/quote/payment' || location.pathname?.match '/main/myprofile' ?
                <img src={darkHambergurMenu} height={20} width={50} alt='hamburgerMenu' /> :
                <img src={headBack ? darkHambergurMenu : hamburgerMenu} height={20} width={50} alt="hambergur" />
            } */}
        </div>
      </div>
      {
        // <div className={`fixed right-0 z-50 ${openHamburger ? 'w-fit' : 'w-0'}`}>
        <div className={`visible fixed right-0 z-50 w-fit lg:hidden`}>
          {/* {openHamburger && <HamburgerMenu
              closeHamburgerMenu={closeHamburgerMenu}
            />} */}
        </div>
      }
      {isAuthorised() && (
        <div className="absolute top-0 flex w-full items-center justify-center border-t-[2.5px] border-[#047AFF]">
          <div className="absolute top-[-1px] z-50 flex h-8 items-center gap-4 rounded-bl rounded-br bg-[#047AFF] px-6">
            <div className="accountName">
              <p className="text-sm text-white">
                You are viewing&emsp;
                <span className="font-roboto text-base font-normal text-white">
                  {getLocalStorageData("user")?.name}
                </span>
                {`'s`}&emsp;account
              </p>
            </div>
            {/* <div className="accountName"><span className="accountNameSpan">|</span>
                <span>Family Plan</span>
              </div> */}
            <div
              className="cursor-pointer pt-1"
              onClick={() => getBackToMainUser()}
            >
              <img src={crossIcon} alt="x" width={15} height={15} />
            </div>
          </div>
        </div>
      )}
    </div>
    // </ClickAwayListener >
  );
};

export default Header;
