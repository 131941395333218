/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
// import shareEmail from '../../Images/invoice/gnshareMail.svg'
// import gnshare from '../../Images/invoice/gnshare.svg'
// import gnshareMail from '../../Images/invoice/gnshareMail.svg'
// import gnMinus from '../../Images/invoice/invoiceMinus.svg'
// import gnPlus from '../../Images/invoice/invoicePlus.svg'
// import logo from "../../Images/logo/Golf_net_logo.png"
import { getAddress, getLabel } from '../../utils/commonFunc'
import getLocalStorageData from '../../utils/getLocalStorageData'
// import { useNavigate } from 'react-router'
// import GotoLoginModal from './GotoLoginModal'

import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import ButtonFieldInput from '../../common/uiComponents/buttonField/Button'
import r from "../../Images/addUser/Rarraow.svg"
import bL from "../../Images/addUser/blackLeft.svg"
import gnPrint from '../../Images/Icons/gnprinting.svg'
import ReactToPrint from "react-to-print";
import { Tooltip } from '@mui/material'
import { doGetApiCall } from '../../utils/ApiConfig'
import editIcon from '../../Images/Icons/whiteEdit.svg'
// import logoBlack from "../../Images/logo/Solar_golfnet_logo_white_background.png";
import logoBlack from "../../Images/logo/SN_Logo_01.png";
import { useNavigate, useSearchParams } from 'react-router-dom'
import GotoLoginModal from './GotoLoginModal'
import MagicLinkModal from '../../common/uiComponents/magicLinkModal/MagicLinkModal'
import ConfirmMagicLinkModal from '../../common/uiComponents/magicLinkModal/ConfirmMagicLinkModal'
import styles from './index.module.css'
import { setNotificationOpen } from '../../container/notificationReducer/NotificationReducer'
import country from '../../common/country.json';

const Invoice = (props: any) => {
  let role = getLocalStorageData('role')
  const invoicePdf: any = useRef(null)
  const imagePdf: any = useRef(null)
  const navigate = useNavigate();
  const [searchParams]: any = useSearchParams();
  const dispatch = useDispatch()

  const invoiceList: any = useSelector((state: any) => state?.invoiceReducer?.invoiceDetails)
  const getUserSupports = useSelector((state: any) => state.diagramReducer?.getAllSupportList);

  // const getUserSupports = useSelector((state: any) => state.supportsReducer?.getSupports)
  const [openGotoLoginModal, setOpenGotoLoginModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)

  // const [invoiceList, setInvoiceList]: any = useState()
  // const [getUserSupports, setGetUserSupports]: any = useState([])
  const nets = getUserSupports?.filter((nets: any) => nets?.type === 'net' && nets)

  useEffect(() => {
    props.getInvoiceDetails()
    props.getSupports()
    console.log("invoiceList==>", invoiceList)
  }, [invoiceList]);

  const handleBack = () => {
    navigate(-1);
  }

  const handleNextPage = () => {
    if (!getLocalStorageData('token')) {
      // setOpenGotoLoginModal(true)
      props.openMagicModal();
    }
    else if (invoiceList?.paymentStatus === 'paid') {
      dispatch(setNotificationOpen({ message: "Failed", subText: `Quote was already paid for.`, alertType: "error", borderClass: "error" }));
    } else {
      props.createPayment(invoiceList?.total, invoiceList?.quoteId?._id)
    }
  }

  const closeGotoLoginModal = () => {
    setOpenGotoLoginModal(false)
  }

  const gotoLogin = () => {
    // navigate(`${process.env.REACT_APP_PUBLIC_GN_URL}login?quoteId=${searchParams.get('quoteId')}&userId=${getLocalStorageData('userId')}&total=${invoiceList?.total}&format=payment`, { replace: true })
    window.location.replace(`${process.env.REACT_APP_PUBLIC_GN_URL}login?quoteId=${searchParams.get('quoteId')}&userId=${getLocalStorageData('userId')}&total=${invoiceList?.total}&format=payment`)
  }

  const backToEditor = () => {
    let userId = getLocalStorageData("userId") ? getLocalStorageData("userId") : getLocalStorageData("user")?._id ? getLocalStorageData("user")?._id : searchParams.get('userId');
    navigate(`/quote/editor?userId=${userId}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}`)
  }


  return (
    <>

      <div>
        <div className='p-4 flex justify-end items-center mr-20 cursor-pointer z-[1] bg-white' >
          <div className={`rounded-full px-2 py-0.5 ${styles.TabComponent}`}>
            <div className={`uppercase ${styles.tabs} ${selectedTab === 0 ? styles.selectedTab : ""}`} onClick={() => setSelectedTab(0)}>Quote</div>
            <div className={`uppercase ${styles.tabs} ${selectedTab === 1 ? styles.selectedTab : ""}`} onClick={() => setSelectedTab(1)}>Image</div>
          </div>
        </div>

        {selectedTab === 0 &&
          <div className={`relative bg-[#E0E0E0] text-gn-black-500 flex justify-center py-8 h-full w-[92vw] rounded`}>
            <div className={`w-[85%]`} >
              <div ref={invoicePdf} className={`bg-white flex flex-col items-center justify-center h-full w-[100%]`}>
                <div className={`flex w-full mb-3 items-center`}>

                  <div className={`flex w-[35%] flex-start pt-4 flex-col`}>
                    <p className={`flex items-start w-full px-8 text-center`}>
                      <span className={`text-sm font-normal font-[SansRegular]`}>SolarNets</span>
                    </p>
                    <p className={`flex items-start w-full px-8 text-center`}>
                      <span className={`text-sm font-normal font-[SansRegular]`}>SunnyCal Solar</span>
                    </p>
                    <p className={`flex items-start w-full px-8 text-center`}>
                      <span className={`text-sm font-normal font-[SansRegular]`}>795 CA-49</span>
                    </p>
                    <p className={`flex items-start w-full px-8 text-center`}>
                      <span className={`text-sm font-normal font-[SansRegular]`}>Jackson, CA 95642</span>
                    </p>
                    <p className={`flex items-start w-full px-8 text-center`}>
                      <span className={`text-sm font-normal font-[SansRegular]`}>209-464-6100</span>
                    </p>
                  </div>

                  <div className={` pt-6 pl-8`}>
                    <img
                      src={logoBlack}
                      alt="logo"
                      height={120}
                      width={140}
                      style={{ objectFit: "contain" }}
                    />
                  </div>

                </div>
                <div className={`flex justify-between items-center w-full px-8 mb-4`}>
                  <div className={`W-[40%]`}>
                    {/* <p className={`flex text-center py-1`}>
                    <h2 className={`text-sm font-bold font-[SansRegular]`}>Sold By :&ensp;</h2>
                    <span className={`text-sm font-normal font-[SansRegular]`}> sunnycalsolar.com</span>
                  </p> */}
                    <p className={`flex text-center py-1`}>
                      <h2 className={`text-sm font-bold font-[SansRegular]`}>Customer Name :&ensp;</h2>
                      <span className={`text-sm font-normal font-[SansRegular]`}> {invoiceList?.createdBy?.name}</span>
                    </p>
                    <p className={`flex text-center py-1`}>
                      <h2 className={`text-sm font-bold font-[SansRegular]`}>Phone :&ensp;</h2>
                      <span className={`text-sm font-normal font-[SansRegular]`}>{invoiceList?.createdBy?.phoneNo}</span>
                    </p>
                    <p className={`flex text-center py-1`}>
                      <h2 className={`text-sm font-bold font-[SansRegular]`}>Email :&ensp;</h2>
                      <span className={`text-sm font-normal font-[SansRegular]`}>{invoiceList?.createdBy?.email}</span>
                    </p>
                    <p className={`flex text-center py-1`}>
                      <h2 className={`text-sm font-bold font-[SansRegular]`}>Quote Date :&ensp;</h2>
                      <span className={`text-sm font-normal font-[SansRegular]`}> {moment(invoiceList?.quoteId?.updatedAt).format("MM/DD/YYYY")} </span>
                    </p>
                  </div>
                  <div className={`w-[40%]`}>
                    <p className={`flex text-center py-1`}>
                      <h2 className={`text-sm font-bold font-[SansRegular]`}>Date :&ensp;</h2>
                      <span className={`text-sm font-normal font-[SansRegular]`}> {moment(invoiceList?.quoteId?.createdAt).format("MM/DD/YYYY")} </span>
                    </p>
                    <p className={`border border-dashed w-11/12 flex items-center justify-center border-[#828282] bg-[#F2F2F2] p-2.5`}>
                      <span className={`text-base font-bold font-[SansRegular]`}>Quote Name&ensp;</span>
                      <span className={`text-sm font-normal font-[SansRegular]`}>{invoiceList?.quoteId?.quoteName}</span>
                    </p>
                    <p className={`flex flex-col pt-4`}>
                      <h2 className={`text-base font-bold font-[SansRegular]`}>Bill To  {invoiceList?.billingAddress?.name}</h2>
                      <span className={`text-sm font-normal font-[SansRegular]`}>{getAddress(invoiceList?.billingAddress?.street, invoiceList?.billingAddress?.city, invoiceList?.billingAddress?.state, invoiceList?.billingAddress?.zipcode, getLabel(invoiceList?.billingAddress?.country, country))}</span>
                    </p>
                    <p className={`flex flex-col pt-4`}>
                      <h2 className={`text-base font-bold font-[SansRegular]`}>{invoiceList?.willCall ? "Will call at" : "Ship To"}  {invoiceList?.shippingAddress?.name}</h2>
                      <span className={`text-sm font-normal font-[SansRegular]`}>{getAddress(invoiceList?.shippingAddress?.street, invoiceList?.shippingAddress?.city, invoiceList?.shippingAddress?.state, invoiceList?.shippingAddress?.zipcode, getLabel(invoiceList?.shippingAddress?.country, country))}</span>
                    </p>
                  </div>
                </div>

                <div className={`w-full`}>
                  <table className={`table-auto w-full`}>
                    <thead className={`bg-[#F2F2F2] w-full h-12 border-y-[#acaaaa] border-y border-solid`}>
                      <tr>
                        <th className={`w-64 text-left pl-8 text-base font-bold font-[SansRegular]`}>Products</th>
                        <th className={`w-40 text-center text-base font-bold font-[SansRegular]`}>Qnty/Size</th>
                        <th className={`w-40 text-left text-base font-bold font-[SansRegular]`}>Units</th>
                        <th className={`w-40 text-right pr-8 text-base font-bold font-[SansRegular]`}>Units Price (USD)</th>
                        <th className={`w-40 text-right pr-8 text-base font-bold font-[SansRegular]`}>Sub-Total</th>
                      </tr>
                    </thead>
                    <tbody className={`w-full`}>
                      {invoiceList?.quoteId?.items?.length > 0 ? invoiceList?.quoteId?.items?.map((item: any, idx: any) => {
                        let netPrice = nets?.filter((net: any) => net?._id === item?.net?._id && net)
                        return (
                          <>
                            <tr key={idx} className={`border-b-[#acaaaa] border-b border-solid`}>
                              <td className={`w-64 text-left py-3 pl-8 text-base font-medium font-[SansRegular] truncate`}>
                                <Tooltip title={`${item?.itemName}(${item?.net?.name})(${item?.netColor === "#FFFFFF" || item?.netColor === "#ffe6e6" ? "White" : item?.netColor === "#D9D9D9" || item?.netColor === "#867979" ? "Black" : item?.netColor})`} placement="top" arrow>
                                  <p className={`truncate w-[224px]`}>{item?.itemName} <span className={`text-sm`}>({item?.net?.name})({item?.netColor === "#FFFFFF" || item?.netColor === "#ffe6e6" ? "White" : item?.netColor === "#D9D9D9" || item?.netColor === "#867979" ? "Black" : item?.netColor})</span></p>
                                </Tooltip>
                              </td>
                              <td className={`w-40 text-center text-base font-medium font-[SansRegular]`}>{item?.quantity}</td>
                              <td className={`w-40 text-left text-base font-medium font-[SansRegular]`}>{Math.round(Number(item?.area))} Sq. ft</td>
                              <td className={`w-40 text-right pr-8 text-base font-medium font-[SansRegular]`}>${Number(item?.unitPrice).toFixed(2) || netPrice[0]?.price}</td>
                              <td className={`w-40 text-right pr-8 text-base font-medium font-[SansRegular]`}>${Number(item?.unitPrice * item?.quantity * item?.area).toFixed(2)}</td>
                            </tr>
                            {
                              item?.chain > 0 &&
                              <tr key={idx} className={`border-b-[#acaaaa] border-b border-solid`}>
                                <td className={`w-64 text-left py-3 pl-8 text-base font-normal font-[SansRegular]`}>Tensioner Chain+3 Carabiners</td>
                                <td className={`w-40 text-center text-base font-normal font-[SansRegular]`}>{item?.chain}</td>
                                <td className={`w-40 text-left text-base font-normal font-[SansRegular]`}>{item?.chain > 1 ? 'Sets' : 'Set'}</td>
                                <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>$29.99</td>
                                <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>${(Number(item?.chain) * 29.99).toFixed(2)}</td>
                              </tr>
                            }
                            {item?.support?.length > 0 && item?.support?.map((support: any, idx: number) => {
                              return (
                                support?.quantity !== null && support?.quantity !== 0 && support?.quantity !== null &&<tr key={idx} className={`border-b-[#cacaca] border-b border-solid`}>
                                  <td className={`w-64 text-left py-3 pl-8 text-base font-normal font-[SansRegular]`}>{support?.id?.name}{!support?.id?.taxable && <span className={`text-blue-500 text-[20px]`}>*</span>}</td>
                                  <td className={`w-40 text-center text-base font-normal font-[SansRegular]`}>{support?.quantity}</td>
                                  <td className={`w-40 text-left text-base font-normal font-[SansRegular]`}>{support?.attachedWith ? (support?.quantity > 1 ? 'Pcs.' : 'Pc.') : (support?.quantity > 1 ? 'Pcs.' : 'Pc.')}</td>
                                  <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>${support?.price || support?.id?.price}</td>
                                  <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>${support?.price ? (support?.price * (support?.quantity)).toFixed(2) : (support?.id?.price * (support?.quantity)).toFixed(2)}</td>
                                </tr>
                              )
                            })}
                          </>
                        )
                      }) : null}

                      {invoiceList?.quoteId?.addOns?.length > 0 && invoiceList?.quoteId?.addOns?.filter((itm: any) => itm?.quantity !== 0 && itm?.quantity !== null)?.map((addOnsData: any, idx: number) =>
                        <tr key={idx} className={`border-b-[#cacaca] border-b border-solid`}>
                          <td className={`w-64 text-left py-3 pl-8 text-base font-normal font-[SansRegular]`}>{addOnsData?.item?.name}{!addOnsData?.item?.taxable && <span className={`text-blue-500 text-[20px]`}>*</span>}</td>
                          <td className={`w-40 text-center text-base font-normal font-[SansRegular]`}>{addOnsData?.quantity}</td>
                          <td className={`w-40 text-left text-base font-normal font-[SansRegular]`}>{addOnsData?.quantity > 1 ? 'Pcs.' : 'Pc.'}</td>
                          <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>${addOnsData?.price || addOnsData?.item?.price}</td>
                          <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>${addOnsData?.price ? (addOnsData?.quantity * addOnsData?.price).toFixed(2) : (addOnsData?.quantity * addOnsData?.item?.price).toFixed(2)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className={`flex flex-col items-end w-full`}>
                  <p className='flex items-center justify-between w-[27rem]  pl-4 py-2 h-12'>
                    <span className='text-left text-base font-[SansRegular] '>Sub-Total(USD)</span>
                    <span className='text-right pr-8 text-base w-[35%] font-[SansRegular]'>${Number(invoiceList?.calPrice?.subTotal).toFixed(2)}</span>
                  </p>

                  <hr className='w-[27rem] border border-dashed border-t-neutral-400 '/>

                  {/* Taxable */}
                 <p className='flex justify-between w-[26rem]  py-2'>
                    <span className='text-left text-base font-[SansRegular]'>Taxable</span>
                    <span className='text-right pr-8 text-base w-[35%] font-[SansRegular]'>${invoiceList?.calPrice?.taxable?.price.toFixed(2)}</span>
                  </p>
                  <p className='flex justify-between w-[26rem]  py-2'>
                    <span className='text-left text-base font-[SansRegular]'>Discount <span>({invoiceList?.calPrice?.discount?.type === 'percentage'? `${invoiceList?.calPrice?.discount?.discount}%` : `$${invoiceList?.calPrice?.discount?.discount} off`})</span></span>
                    <span className='text-right pr-8 text-base w-[35%] font-[SansRegular] text-red-500'>-${invoiceList?.calPrice?.taxable?.taxableDiscount?.toFixed(2)}</span>
                  </p>

                  <p className='flex items-center justify-between w-[27rem]  pl-4 py-2 h-12 text-neutral-700'>
                    <span className='text-left text-base font-bold font-[SansRegular] '>Price with discount(USD)</span>
                    <span className='text-right pr-8 text-base w-[35%] font-bold font-[SansRegular]'>${invoiceList?.calPrice?.taxable?.priceWithDiscount?.toFixed(2)}</span>
                  </p>
                  
                  <hr className='w-[27rem] border border-dashed border-t-neutral-400'/>

                  {/* Non Taxable */}

                  <p className='flex justify-between w-[26rem]  py-2'>
                    <span className='text-left text-base font-[SansRegular]'>Non Taxable</span>
                    <span className='text-right pr-8 text-base w-[35%] font-[SansRegular]'>${invoiceList?.calPrice?.nonTaxable?.price.toFixed(2)}</span>
                  </p>
                  <p className='flex justify-between w-[26rem]  py-2'>
                    <span className='text-left text-base font-[SansRegular]'>Discount <span>({invoiceList?.calPrice?.discount?.type === 'percentage'? `${invoiceList?.calPrice?.discount?.discount}%` : `$${invoiceList?.calPrice?.discount?.discount} off`})</span></span>
                    <span className='text-right pr-8 text-base w-[35%] font-[SansRegular] text-red-500'>-${invoiceList?.calPrice?.nonTaxable?.nonTaxableDiscount?.toFixed(2)}</span>
                  </p>

                  <p className='flex items-center justify-between w-[27rem] pl-4 py-2 h-12 text-neutral-700'>
                    <span className='text-left text-base font-bold font-[SansRegular] '>Price with discount(USD)</span>
                    <span className='text-right pr-8 text-base w-[35%] font-bold font-[SansRegular]'>${invoiceList?.calPrice?.nonTaxable?.priceWithDiscount?.toFixed(2)}</span>
                  </p>

                  <hr className='w-[27rem] border border-dashed border-t-neutral-400 '/>

                  {/* Shipping Handling Charge */}
                  {/* {searchParams.get('offerValue') &&
                    <p className='flex justify-between w-[26rem]  py-2'>
                      <span className='text-left text-base font-[SansRegular]'>{`Discount`}</span>
                      <span className='text-right pr-8 text-base w-[35%] font-[SansRegular]'>{`- $${Number(searchParams.get('offerValue')).toFixed(2)}`}</span>
                    </p>} */}
                  {(!invoiceList?.willCall && invoiceList?.shippingAddress?.country === 'US') && <p className='flex justify-between w-[26rem]  py-2'>
                    <span className='text-left text-base font-[SansRegular]'>Shipping & Handling</span>
                    {invoiceList?.shippingAddress?.country !== 'US' && <span className='text-right pr-8 text-base w-[55%] font-[SansRegular]'>** Shipping charges to be billed separately</span>}
                    {invoiceList?.shippingAddress?.country === 'US' && <span className='text-right pr-8 text-base w-[35%] font-[SansRegular] font-bold'>${Number(invoiceList?.calPrice?.shipping).toFixed(2)}</span>}
                  </p>}
                  {/* <p className='flex justify-between w-[26rem]  py-2'>
                  <span className='text-left text-base font-[SansRegular]'>Total</span>
                  <span className='text-right pr-8 text-base w-[35%] font-[SansRegular]'>${invoiceList?.discount?.percentage ? (Number(invoiceList.discount.price + invoiceList.shipping).toFixed(2)) : (Number(invoiceList.subTotal + invoiceList.shipping).toFixed(2))}</span>
                </p> */}


                  <p className='flex justify-between w-[26rem]  py-2'>
                    <span className='text-left text-base font-[SansRegular]'>Tax ({Number(invoiceList?.calPrice?.taxRate * 100).toFixed(2)}%)</span>
                    <span className='text-right pr-8 text-base w-[35%] font-[SansRegular] font-bold'>${Number(invoiceList?.calPrice?.tax).toFixed(2)}</span>
                  </p>
                  <p className='flex justify-between items-center w-[27rem] pl-4 py-2 h-12 bg-[#E0E0E0]'>
                    <span className='text-left text-lg font-extrabold font-[SansRegular]'>Grand Total(USD)*</span>
                    <span className='text-right pr-8 text-lg w-[35%] font-extrabold font-[SansRegular]'>${invoiceList?.calPrice?.total?.toFixed(2)}</span>
                  </p>
                </div>

                <div className={`flex flex-col items-start w-full px-8 py-4`}>
                  <h1 className={`text-base font-light font-[SansRegular]`}>Thank You</h1>
                  <p className={`text-sm font-medium font-[SansRegular]`}>
                    {invoiceList?.createdBy?.name} for your interest</p>
                </div>

                <div className={`border-t-2 border-t-[#BDBDBD] border-solid py-4 mx-8`}>
                  <h2 className={`text-sm italic font-normal font-[SansRegular]`}>*Note:</h2>
                  <ul className='list-disc ml-5 text-[18px]'>
                  <li className={`text-sm font-normal font-[SansRegular]`}>Above proposal content represents the best effort configuration based on customer representations. Buyer is ultimately
                    responsible for component adequacy. Orders are custom built, and are not eligible for return or refunds.</li>
                  <li className={`text-sm font-normal font-[SansRegular]`}><span className={`text-[20px] text-blue-500`}>* </span>{` indicates non-taxable items`}</li>
                  <li className={`text-sm font-normal font-[SansRegular]`}>A fee of $500 return visit will be charged if job site is not available for installation on the appointed day.</li>
                  </ul>
                </div>

              </div>
            </div>
            {/* <div className='absolute top-8 right-4'>
          <div className={`mb-2.5 cursor-pointer bg-white flex justify-center items-center rounded-[100%] px-3 py-3 h-full w-full`}>
            <img src={shareEmail} alt={`shareEmail`} height={20} width={20} priority />
          </div>
          <div className={`mb-2.5 cursor-pointer bg-white flex justify-center items-center rounded-[100%] px-3 py-3 h-full w-full`}>
            <img src={gnshareMail} alt={`gnshareMail`} height={20} width={20} priority />
          </div>
          <div className={`mb-2.5 cursor-pointer bg-white flex justify-center items-center rounded-[100%] px-3 py-3 h-full w-full`}>
            <img src={gnshare} alt={`gnshare`} height={20} width={20} priority />
          </div>
        </div> */}
            <div className={`flex items-end flex-col gap-4 absolute top-10 right-8`}>
              <ReactToPrint
                trigger={() => <div className={`border-[#D9D9D9] bg-white border-[2px] h-10 w-10 flex justify-center items-center rounded-full cursor-pointer`}>
                  <img src={gnPrint} height={18} width={18} alt='printIcon' />
                </div>}
                content={() => invoicePdf.current}
                documentTitle={invoiceList?.quoteId?.quoteName}
                pageStyle='@page { size: 8.5in 11in }'
              />
            </div>
          </div>}

        {
          selectedTab === 1 &&
          <div className={`relative bg-[#E0E0E0] text-gn-black-500 flex justify-center py-8 mt-4 h-full w-[92vw] rounded`}>
            <div className={`w-[85%]`}>
              <div ref={imagePdf} className={`bg-white flex flex-col items-center justify-center h-full w-[100%] py-[1rem]`}>
                {invoiceList?.quoteId?.canvasData ?
                  <div className='mt-4'>
                    {invoiceList?.quoteId?.canvasData && invoiceList?.quoteId?.items?.map((item: any, index: number) => {
                      return (
                        <>
                          <div className={`text-black text-[14px] w-full h-min flex mb-4`}>
                            <div className={`flex justify-around text-black text-[14px] w-3/4 page-break h-[300px]`}>
                              <img
                                crossOrigin="anonymous"
                                src={`${process.env.REACT_APP_PUBLIC_apiurl}/uploads/?key=${item?.diagram?.key}&width=50&height=50`} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="Canvas shapes" />
                            </div>

                            <div className='ml-5 mt-11' key={index}>
                              <p className="font-[roboto]">Net Name:- {item?.itemName}</p>
                              <p className="font-[roboto]">Area:- {Math.round(Number(item?.area))} sq.ft</p>
                              <p className="font-[roboto]">Max Height:- {item?.height} ft.</p>
                              <p className="font-[roboto]">Max Width:- {item?.width} ft.</p>
                              <p className="font-[roboto capitalize]">Net Color:- {item?.netColor === "#FFFFFF" || item?.netColor === "#ffe6e6" ? "White" : item?.netColor === "#D9D9D9" || item?.netColor === "#867979" ? "Black" : item?.netColor}
                              </p>
                              <p className="font-[roboto]">Solar Module Thickness:- {item?.thickness ? `${item?.thickness} mm` : 'NA'}</p>
                              <p className="font-[roboto]">Net Type:- {item?.net?.name}</p>
                            </div>
                          </div>
                          <p style={{ 'pageBreakAfter': 'always' }}></p>
                        </>
                      )
                    })
                    }
                  </div>
                  :
                  <div className='relative w-[80%] ml-8 mt-10 page-image'>
                    {!invoiceList?.quoteId?.canvasData && invoiceList?.quoteId?.items?.map((item: any, index: number) => {
                      return (
                        <div className={'flex items-center justify-around pb-4 text-black text-[14px] w-full page-break h-[400px]'} key={index}>
                          <div className='pl-4'>
                            <p className="font-[roboto]">Net Name:- {item?.itemName}</p>
                            <p className="font-[roboto]">Area:- {Math.round(Number(item?.area))} sq.ft</p>
                            <p className="font-[roboto]">Max Height:- {item?.height} ft.</p>
                            <p className="font-[roboto]">Max Width:- {item?.width} ft.</p>
                            <p className="font-[roboto] text-black capitalize">Net Color:- {item?.netColor === "#FFFFFF" || item?.netColor === "#ffe6e6" ? "White" : item?.netColor === "#D9D9D9" || item?.netColor === "#867979" ? "Black" : item?.netColor}</p>
                            <p className="font-[roboto]">Solar Module Thickness:- {item?.thickness ? `${item?.thickness} mm` : 'NA'}</p>
                            <p className="font-[roboto]">Net Type:- {item?.net?.name}</p>
                          </div>
                        </div>
                      )
                    })}

                  </div>
                }
              </div>
            </div>
            <div className={`flex items-end flex-col gap-4 absolute top-10 right-8`}>
              <ReactToPrint
                trigger={() => <div className={`border-[#D9D9D9] bg-white border-[2px] h-10 w-10 flex justify-center items-center rounded-full cursor-pointer`}>
                  <img src={gnPrint} height={18} width={18} alt='printIcon' />
                </div>}
                content={() => imagePdf.current}
                documentTitle={invoiceList?.quoteId?.quoteName}
                pageStyle='@page { size: 8.5in 11in }'
              />
            </div>
          </div>
        }
        <div className={`flex justify-center items-center`}>
          <div className={`flex justify-between w-[16rem] sm:w-[30rem] py-10`}>
            <div className={`border border-[#E0E0E0] rounded-3xl`}>
              <ButtonFieldInput
                type={`submit`}
                name={`Back`}
                startIcon={<img src={bL} width={22} height={22} alt="arrow" />}
                handleButton={handleBack}
                buttonextracls={`!text-black text-base !py-2 !px-6 !font-normal !font-[SansRegular] cursor-pointer`}
              />
            </div>
            <div className={`mr-4`}>
              <ButtonFieldInput
                name="Edit Net"
                type="reset"
                handleButton={backToEditor}
                endIcon={<img src={editIcon} width={18} height={18} alt="arrow" />}
                buttonextracls={`!text-white text-base !bg-[#2F80ED] !hover:bg-[#2F80ED] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
              />
            </div>
            <div className={`flex justify-center items-center`}>
              <div className={`mr-4 border border-[#E0E0E0] rounded-3xl`}>
                <ButtonFieldInput
                  name="Next"
                  type="submit"
                  disabled={role === 'admin' || props.addressLoading === true || invoiceList?.paymentStatus === 'paid' ? true : false}
                  // disabled={isAuthorised()}
                  // handleButton={() => { props.createPayment(invoiceList?.total) }}
                  handleButton={() => { handleNextPage() }}
                  endIcon={<img src={r} width={18} height={18} alt="arrow" />}
                  // buttonextracls={`${isAuthorised() ? '!bg-[#989898] !hover:bg-[#989898]' : '!bg-[#2F80ED] !hover:bg-[#2F80ED]'} !text-white text-base  !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular]`}
                  buttonextracls={`!bg-[#2F80ED] !hover:bg-[#2F80ED] !text-white text-base  !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular]`}
                  loading={props.addressLoading}
                />
              </div>
            </div>
          </div>
          {invoiceList?.paymentStatus === 'paid' && <p style={{ color: "red" }}>Already paid for the quote.</p>}
        </div>
      </div>
      {/* Login to payment modal */}
      <GotoLoginModal
        open={openGotoLoginModal}
        onClose={closeGotoLoginModal}
        gotoLogin={gotoLogin}
      />
      {
        props?.openMagicForm ?
          <MagicLinkModal
            open={props.openMagicForm}
            onClose={props.closeMagicModal}
            magicLinkForm={props.magicLinkForm}
            magicFormBtnLoader={props.magicFormBtnLoader}
            title={`Login to make payment`}
            description={`We are glad that your net design is ready, to make payment you need to login.To login kindly click 
            "Generate magic link.`}
          />
          : null
      }
      {
        props.mailCheckModalOpen ?
          <ConfirmMagicLinkModal
            isClose={true}
            open={props.mailCheckModalOpen}
            netSupportRedirect={props.netSupportRedirect}
            handlecloseCheckMailModal={props.handlecloseCheckMailModal}
            resendEmail={props.createMagicLink}
          />
          : null
      }
    </>

  )
}

export default Invoice