import { fabric } from "fabric";

const getTopLeftPointOfPolygon = (polygon: fabric.Polygon | any) => {
    if (polygon) {
        let topLeftPoint = { x: Infinity, y: Infinity };
        const nearestTwoPoints = findNearestTwoPoints(polygon.aCoords.tl, polygon.points);
        topLeftPoint = findTopmostPoint(nearestTwoPoints);

        return topLeftPoint;
    }
    else {
        return null;
    }
}

function findTopmostPoint(points: any) {
    // Find the point with the smallest y-coordinate
    return points.reduce((topmost: any, point: any) =>
        point.y < topmost.y ? point : topmost
    );
}

function findNearestTwoPoints(topLeftPoint: any, points: any) {
    function distance(p1: any, p2: any) {
        return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
    }

    const sortedPoints = points
        .map((point: any) => ({ point, dist: distance(topLeftPoint, point) }))
        .sort((a: any, b: any) => a.dist - b.dist);

    return sortedPoints.slice(0, 2).map((item: any) => item.point);
}

export const getManufacturerTag = (polygon: any) => {
    let leftPoint: fabric.Point | any = getTopLeftPointOfPolygon(polygon);
    console.log(leftPoint, "###")
    const flagObj = {
        fill: new fabric.Gradient({
            type: 'linear',
            gradientUnits: 'percentage',
            coords: { x1: 0, y1: 0, x2: 0, y2: 1 },
            colorStops: [
                { offset: 0, color: '#fb0d05' },
                { offset: 0.5, color: '#05fb18' },
                { offset: 1, color: '#0505fb' },
            ],
        }),
        selectable: false,
        objectType: 'manufacturer-tag',
        left: leftPoint.x,
        top: leftPoint.y,
        width: 30,
        height: 20,
        originX: 'right',
        originY: 'center',
        visible: false
    }
    let flagRect = new fabric.Rect(flagObj);
    return flagRect;
   
}

