import net from "../../Images/sidenav/solarnet.svg"
import panel from "../../Images/sidenav/solarpanel.svg"
import support from '../../Images/sidenav/support.svg'

import Net_Icon from "../../Images/sidenav/01.png"
import Drag_Net_Icon from "../../Images/sidenav/02.png"

export const sideNavData = [
    { img: panel, title: 'Solar Panel', keyword: "step1_solar_panel" },
    { img: net, title: 'Solar Net', keyword: "step2_solar_net" },
    { img: support, title: 'Support', keyword: "step3_support" },
]

export const sideNavAssemblyData = [
    { isAsemblyEditor: true, img: Net_Icon, title: 'Add Ropes', keyword: "step1_solar_nets", type: 'rope_support', draggable: false },
    { isAsemblyEditor: true, img: Drag_Net_Icon, title: 'Assemble Net', keyword: "step1_admin_support", type: 'wire_support', draggable: true },
]