import {
    Dialog,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonFieldInput from "../buttonField/Button";
import crossicon from "../../../Images/Icons/cross.svg";
import TextFieldInput from "../FormFields/TextFieldInput";
import getLocalStorageData from "../../../utils/getLocalStorageData";
import LoginCard from "../LoginCard";
import MultiInputOtp from "../otpComponent/MultiInputOtp";
import ReverseCountDown from "../TimerCountdown/ReverseCountDown";
const OTP_VALIDITY_DURATION = 300000; // 5 minutes in milliseconds

const OtpLoginModal = (props: any) => {

    const [otpGeneratedTime, setOtpGeneratedTime] = useState(0);

    useEffect(() => {
        const storedOtpTime: any = localStorage.getItem('otpGeneratedTime');
        const currentTime: any = new Date().getTime();

        if (storedOtpTime && currentTime - storedOtpTime < OTP_VALIDITY_DURATION) {
            setOtpGeneratedTime(storedOtpTime);
        } else {
            handleGenerateOtp();
        }
    }, []);

    const handleGenerateOtp = () => {
        props.generateLoginOTP();
        const currentTime: any = new Date().getTime();
        localStorage.setItem('otpGeneratedTime', currentTime);
        setOtpGeneratedTime(currentTime);
    }

    const handleResendOtp = () => {
        props.resendOTP("login");
        const currentTime: any = new Date().getTime();
        localStorage.setItem('otpGeneratedTime', currentTime);
        setOtpGeneratedTime(currentTime);
    }

    useEffect(() => {
        if (props.inputOtp?.length > 5) {
            props.verifyLoginOTP(props.inputOtp);
        }
    }, [props.inputOtp?.length]);

    return (
        <div>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle className={`!py-5`}>
                    <div className={`flex items-center justify-between h-2`}>
                        <p className={`text-xl text-black font-medium`}>{props?.title}</p>
                        <div className={`cursor-pointer`} onClick={props.onClose}>
                            <img src={crossicon} alt="crossicon" height={15} width={15} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <LoginCard
                        title="You're just a step away"
                        description="we’ve sent you a six-digit confirmation code to your email. Please enter it below to confirm your email address."
                    >
                        <div className="grid grid-cols-1 gap-4">

                        <TextFieldInput
                            fullWidth={true}
                            style={{
                                boxShadow: "inset 0px 4px 8px rgba(0, 0, 0, 0.05)",
                            }}
                            textnewclass={`bg-gn-white-10 rounded-[8px] w-[100%]`}
                            placeholder={`Enter Email`}
                            type={`text`}
                            textinputname={`email`}
                            id={`email`}
                            value={props.userEmail}
                            disabled={true}
                        />
                        <div className='w-full'>
                            <MultiInputOtp inputOtp={props.inputOtp} setInputOtp={props.setInputOtp} isInvalidCode={props.isInvalidCode} isValidCode={props.isValidCode} />
                            <div className='flex justify-end mt-2'>
                                <ReverseCountDown
                                    beforeTitle="Request new code in"
                                    afterTitle="Request for new Code"
                                    timeValue={OTP_VALIDITY_DURATION - (new Date().getTime() - otpGeneratedTime)}
                                    onResend={handleResendOtp}
                                />
                            </div>
                        </div>
                        </div>

                        <ButtonFieldInput
                            type="submit"
                            name="Continue"
                            loading={props.loader || props.isValidCode}
                            disabled={props.inputOtp?.length < 6 || props.isValidCode}
                            buttonextracls={`w-full !bg-[#2C71F6]/[0.9] !hover:bg-[#2C71F6] duration-300 transition-colors !text-white !py-3 rounded-lg font-[RobotoSemiBold]`}
                        />

                    </LoginCard>

                </DialogContent>
            </Dialog>
        </div>
    );
};

export default OtpLoginModal;
