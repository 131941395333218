import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ButtonFieldInput from "./buttonField/Button";
import next from "../../Images/commonImg/Rarraow.svg";
import { useDispatch, useSelector } from "react-redux";
import help_icon from "../../Images/help_icon.svg";
import { fetchHelpContent } from "../../redux/helpModalSlice";

const QuoteDetailsModal = (props: any) => {

  const dispatch = useDispatch()
  const quoteData = useSelector(
    (state: any) => state.diagramReducer?.quoteData
  );
  const subTotal = () => {
    let totalPrice: number = 0;
    let sum: number = 0;
    let price: number = 0;
    if (quoteData?.items?.length > 0) {
      quoteData?.items?.map((item: any) => {
        let netPriceCal: any =
          Number(item?.area) * Number(item?.net?.price) * item?.quantity || 0;
        let supportCal = item?.support?.reduce(
          (a: any, c: any) => a + Number(c.id.price) * Number(c?.quantity),
          0
        );
        let total: number =
          Number(item?.chain) * 29.99 + netPriceCal + supportCal;
        sum += total;
      });
      // return sum.toFixed(2);
    }
    if (quoteData?.addOns?.length > 0) {
      quoteData?.addOns?.map((addon: any) => {
        price += Number(addon?.item?.price) * addon?.quantity;
      });
      // return price.toFixed(2);
    }
    totalPrice = sum + price;
    return Number(totalPrice).toFixed(2);
  };

  return (
    <div className="">
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        className={`quoteDetailsModal`}
      >
        <DialogTitle
          // id='responsive-dialog-title'
          className={`flex items-center justify-between border-b border-slate-200 px-3 py-3 text-black ${props.dialogTitlecls}`}
        >
          {"Quote Details"}
          <CloseIcon
            onClick={props.handleClose}
            className={`cursor-pointer text-slate-500`}
          />
        </DialogTitle>
        <DialogContent>
          <div
            className={`mt-4 flex flex-col overflow-y-auto`}
          >
            {/** Quote Details */}
            <div className={`px-4 flex items-center justify-center`}>
              <table className={`w-[78vw] table-auto`}>
                <thead
                  className={`h-12 border-y border-solid border-y-[#acaaaa] bg-[#F2F2F2]`}
                >
                  <tr>
                    <th
                      className={`w-64 pl-8 text-left font-[SansRegular] text-base font-bold`}
                    >
                      Products
                    </th>
                    <th
                      className={`w-40 text-center font-[SansRegular] text-base font-bold`}
                    >
                      Qnty/Size
                    </th>
                    <th
                      className={`w-40 text-left font-[SansRegular] text-base font-bold`}
                    >
                      Units
                    </th>
                    <th
                      className={`w-40 pr-8 text-right font-[SansRegular] text-base font-bold`}
                    >
                      Units Price (USD)
                    </th>
                    <th
                      className={`w-40 pr-8 text-right font-[SansRegular] text-base font-bold`}
                    >
                      Subtotal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quoteData?.items?.length > 0
                    ? quoteData?.items?.map((item: any, idx: any) => {
                      return (
                        <>
                          <tr
                            key={idx}
                            className={`border-b border-solid border-b-[#acaaaa]`}
                          >
                            <td
                              className={`w-64 truncate py-3 pl-8 text-left font-[SansRegular] text-base font-medium`}
                            >
                              <Tooltip
                                title={`${item?.itemName}(${item?.net?.name
                                  })(${item?.netColor === "#FFFFFF" ||
                                    item?.netColor === "#ffe6e6"
                                    ? "White"
                                    : item?.netColor === "#D9D9D9" ||
                                      item?.netColor === "#867979"
                                      ? "Black"
                                      : item?.netColor
                                  })`}
                                placement="top"
                                arrow
                              >
                                <p className={`w-[224px] truncate capitalize`}>
                                  {item?.itemName}{" "}
                                  <span className={`text-sm`}>
                                    ({item?.net?.name})(
                                    {item?.netColor === "#FFFFFF" ||
                                      item?.netColor === "#ffe6e6"
                                      ? "White"
                                      : item?.netColor === "#D9D9D9" ||
                                        item?.netColor === "#867979"
                                        ? "Black"
                                        : item?.netColor}
                                    )
                                  </span>
                                </p>
                              </Tooltip>
                            </td>
                            <td
                              className={`w-40 text-center font-[SansRegular] text-base font-medium`}
                            >
                              {item?.quantity}
                            </td>
                            <td
                              className={`w-40 text-left font-[SansRegular] text-base font-medium`}
                            >
                              {Math.round(Number(item?.area))} Sq. ft
                            </td>
                            <td
                              className={`w-40 pr-8 text-right font-[SansRegular] text-base font-medium`}
                            >
                              ${Number(item?.net?.price).toFixed(2)}
                            </td>
                            <td
                              className={`w-40 pr-8 text-right font-[SansRegular] text-base font-medium`}
                            >
                              $
                              {(
                                Number(item?.area) *
                                Number(item?.net?.price) *
                                item?.quantity
                              ).toFixed(2)}
                            </td>
                          </tr>
                          {item?.chain > 0 && (
                            <tr
                              key={idx}
                              className={`border-b border-solid border-b-[#acaaaa]`}
                            >
                              <td
                                className={`w-64 py-3 pl-8 text-left font-[SansRegular] text-base font-normal`}
                              >
                                Tensioner Chain+3 Carabiners
                              </td>
                              <td
                                className={`w-40 text-center font-[SansRegular] text-base font-normal`}
                              >
                                {item?.chain}
                              </td>
                              <td
                                className={`w-40 text-left font-[SansRegular] text-base font-normal`}
                              >
                                {item?.chain > 1 ? "Sets" : "Set"}
                              </td>
                              <td
                                className={`w-40 pr-8 text-right font-[SansRegular] text-base font-normal`}
                              >
                                $29.99
                              </td>
                              <td
                                className={`w-40 pr-8 text-right font-[SansRegular] text-base font-normal`}
                              >
                                ${(Number(item?.chain) * 29.99).toFixed(2)}
                              </td>
                            </tr>
                          )}
                          {item?.support?.length > 0 &&
                            item?.support?.map(
                              (support: any, idx: number) => {
                                return (
                                  support?.quantity !== null &&
                                  support?.quantity !== 0 && support?.quantity !== null &&(
                                    <tr
                                      key={idx}
                                      className={`border-b border-solid border-b-[#cacaca]`}
                                    >
                                      <td
                                        className={`w-64 py-3 pl-8 text-left font-[SansRegular] text-base font-normal`}
                                      >
                                        {support?.id?.name}{!support?.id?.taxable && <span className={`text-blue-500 text-[20px]`}>*</span>}
                                      </td>
                                      <td
                                        className={`w-40 text-center font-[SansRegular] text-base font-normal`}
                                      >
                                        {support?.quantity}
                                      </td>
                                      <td
                                        className={`w-40 text-left font-[SansRegular] text-base font-normal`}
                                      >
                                        {support?.attachedWith
                                          ? support?.quantity > 1
                                            ? "Pcs."
                                            : "Pc."
                                          : support?.quantity > 1
                                            ? "Pcs."
                                            : "Pc."}
                                      </td>
                                      <td
                                        className={`w-40 pr-8 text-right font-[SansRegular] text-base font-normal`}
                                      >
                                        ${support?.id?.price}
                                      </td>
                                      <td
                                        className={`w-40 pr-8 text-right font-[SansRegular] text-base font-normal`}
                                      >
                                        $
                                        {(
                                          support?.id?.price *
                                          support?.quantity
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                  )
                                );
                              }
                            )}
                        </>
                      );
                    })
                    : null}

                  {quoteData?.addOns?.length > 0
                    ? quoteData?.addOns
                      ?.filter(
                        (itm: any) =>
                          itm?.quantity !== 0 && itm?.quantity !== null
                      )
                      .map((addon: any, idx: number) => {
                        return (
                          <tr
                            key={idx}
                            className={`border-b border-solid border-b-[#cacaca]`}
                          >
                            <td
                              className={`w-64 py-3 pl-8 text-left font-[SansRegular] text-base font-normal`}
                            >
                              {addon?.item?.name} {!addon?.item?.taxable && <span className={`text-blue-500 text-[20px]`}>*</span>}
                            </td>
                            <td
                              className={`w-40 text-center font-[SansRegular] text-base font-normal`}
                            >
                              {addon?.quantity}
                            </td>
                            <td
                              className={`w-40 text-left font-[SansRegular] text-base font-normal`}
                            >
                              {addon?.attachedWith
                                ? addon?.quantity > 1
                                  ? "Pcs."
                                  : "Pc."
                                : addon?.quantity > 1
                                  ? "Pcs."
                                  : "Pc."}
                            </td>
                            <td
                              className={`w-40 pr-8 text-right font-[SansRegular] text-base font-normal`}
                            >
                              ${addon?.item?.price}
                            </td>
                            <td
                              className={`w-40 pr-8 text-right font-[SansRegular] text-base font-normal`}
                            >
                              $
                              {(addon?.item?.price * addon?.quantity).toFixed(
                                2
                              )}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>

            {/**Quote Pice section */}
            <div className={`flex w-full flex-col items-end`}>
              <p className="flex w-[27rem] items-center justify-between bg-[#E0E0E0] py-2 pl-4">
                <span className="text-left font-[SansRegular] text-base font-bold">
                  Grand Total* (USD)
                </span>
                <span className="w-[35%] pr-8 text-right font-[SansRegular] text-base font-bold">
                  ${subTotal()}
                </span>
              </p>
              <span className={`font-[SansRegular] text-sm text-red-600`}>
                *Shipping & tax not included
              </span>
            </div>

            {/**Quote Diagram */}
            <div className="">
              {quoteData &&
                quoteData?.items?.map((item: any, index: number) => {
                  return (
                    <div className={`mb-4 flex w-full text-[14px] text-black items-center`}>
                      <div
                        className={`page-break flex h-[300px] w-[80%] justify-around pb-4 text-[14px] text-black`}
                      >
                        <img
                          crossOrigin="anonymous"
                          src={`${process.env.REACT_APP_PUBLIC_apiurl}/uploads/?key=${item?.diagram?.key}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          alt="Canvas shapes"
                        />
                      </div>
                      <div className="flex items-center w-[20%]" key={index}>
                        <div className="w-full">
                        <p className="font-[roboto]">
                          Net Name:- {item?.itemName}
                        </p>
                        <p className="font-[roboto]">
                          Area:- {Math.round(Number(item?.area))} sq.ft
                        </p>
                        <p className="font-[roboto]">
                          Max Height:- {item?.height} ft.
                        </p>
                        <p className="font-[roboto]">
                          Max Width:- {item?.width} ft.
                        </p>
                        <p className="font-[roboto] capitalize">
                          Net Color:-{" "}
                          {item?.netColor === "#FFFFFF" ||
                            item?.netColor === "#ffe6e6"
                            ? "White"
                            : item?.netColor === "#D9D9D9" ||
                              item?.netColor === "#867979"
                              ? "Black"
                              : item?.netColor}
                        </p>
                        <p className="font-[roboto]">
                          Solar Module Thickness:-{" "}
                          {item?.thickness ? `${item?.thickness} mm` : "NA"}
                        </p>
                        <p className="font-[roboto]">
                          Net Type:- {item?.net?.name}
                        </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={`border-t-2 border-t-[#BDBDBD] border-solid py-4 mx-8 w-full`}>
              <h2 className={`text-sm italic font-normal font-[SansRegular]`}>*Note:</h2>
              <ul className='list-disc ml-5 text-[18px]'>
                <li className={`text-sm font-normal font-[SansRegular]`}><span className={`text-[20px] text-blue-500`}>* </span>{` indicates non-taxable items`}</li>
              </ul>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={`flex justify-between gap-3 px-6 py-4`}>
            <div className="flex items-center justify-between gap-6">
            <div onClick={()=> dispatch(fetchHelpContent({ keywordItem: "quote_details", type: null}))} className="relative flex cursor-pointer items-center justify-between rounded-r-2xl bg-[#2c9ddb] px-1 text-white">
            <img
                  className="absolute -left-6 h-9 w-9 rounded-full border-2 border-white"
                  src={help_icon}
                  alt="icon"
                />
                <p className="mx-3 font-semibold">Help</p>
              </div>
            </div>
            <div className={`rounded-3xl border border-[#E0E0E0]`}>
              <ButtonFieldInput
                type={`submit`}
                name={`Cancel`}
                handleButton={props.handleClose}
                buttonextracls={`!text-black text-base !py-2 !px-6 !font-normal !font-[SansRegular] cursor-pointer`}
              />
            </div>
            <ButtonFieldInput
              name="Next"
              type="submit"
              handleButton={async () => {
                await props?.navigateToNextPage();
                props.handleClose();
              }}
              endIcon={<img src={next} width={18} height={18} alt="arrow" />}
              buttonextracls={`!text-white text-base !bg-[#2F80ED] !hover:bg-[#2F80ED] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
              loading={props?.supportLoading}
              disabled={props?.supportLoading === true && true}
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QuoteDetailsModal;
