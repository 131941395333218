import React, { useState, useEffect, useRef } from 'react';

interface ReverseCountDownProps {
  timeValue: number;
  beforeTitle: string;
  afterTitle: string;
  onResend: any;
}

const ReverseCountDown: React.FC<ReverseCountDownProps> = ({ timeValue, beforeTitle, afterTitle, onResend }) => {
  const [timeLeft, setTimeLeft] = useState(timeValue);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft === 0) {
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
          return 0;
        }
        return prevTimeLeft - 1000;
      });
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setTimeLeft(timeValue);
  }, [timeValue]);

  const handleResendClick = () => {
    onResend();
    setTimeLeft(timeValue);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    startTimer();
  };

  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return (
    <div className='flex items-center font-[RobotoSemiBold] text-[#2C71F6] text-[12px] md:text-[16px]'>
      {timeLeft > 0 ? (
        <h1 className='font-[RobotoSemiBold]'>
          {beforeTitle}
          <span className='ml-2 font-[RobotoSemiBold]'>{`${formattedMinutes}:${formattedSeconds}`}</span>
        </h1>
      ) : (
        <button className="ml-2 text-[#2C71F6] cursor-pointer" onClick={handleResendClick}>
          {afterTitle}
        </button>
      )}
    </div>
  );
};

export default ReverseCountDown;
