import React, { useState } from 'react';
import OtpInput from 'react-otp-input';

const MultiInputOtp = ({ inputOtp, setInputOtp, isInvalidCode, isValidCode }: any) => {


    return (
        <div className='w-full'>
            <OtpInput
                value={inputOtp}
                onChange={setInputOtp}
                numInputs={6}
                inputType="number"
                renderSeparator={<span> - </span>}
                renderInput={(props) => <input {...props} className={`
                ${isInvalidCode? "border-[#eebd38]" : ` ${isValidCode? "border-[#3ddd4a]" : "border-[#dadada]"}`} 
                
                border-2 focus:outline-none select-none scroll-none
                w-full h-[50px] md:h-[60px] rounded-lg text-center font-[RobotoBold] text-[22px]`} />}
                containerStyle="w-full gap-[2px] md:gap-2 grid grid-cols-6"
                skipDefaultStyles={true}
                shouldAutoFocus={true}
            />
        </div>
    )
}

export default MultiInputOtp
