import {
    Dialog,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React from "react";
import ButtonFieldInput from "../buttonField/Button";
import crossicon from "../../../Images/Icons/cross.svg";
import TextFieldInput from "../FormFields/TextFieldInput";
import getLocalStorageData from "../../../utils/getLocalStorageData";
import LoginCard from "../LoginCard";
import PasswordFieldInput from "../commonPasswordField/PasswordField";

const PasswordLoginModal = (props: any) => {
    return (
        <div>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle className={`!py-5`}>
                    <div className={`flex items-center justify-between h-2`}>
                        <p className={`text-xl text-black font-medium`}>{props?.title}</p>
                        <div className={`cursor-pointer`} onClick={props.onClose}>
                            <img src={crossicon} alt="crossicon" height={15} width={15} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {/* <div className={`w-[100%] md:hidden flex items-center justify-end py-2 absolute right-3 top-3`} onClick={props.onClose}>
                    <img src={crossicon} alt="crossicon" height={15} width={15} />
                        
                        </div> */}
                    <LoginCard
                        title="Begin Your Journey Here"
                        description="Become a part of the solar revolution. Access your account to customize your solar net preferences."
                    >
                        <form className="grid grid-cols-1 gap-4" onSubmit={props.loginWithPasswordForm.handleSubmit}>
                            <div className="w-full grid grid-cols-1 gap-3 mb-6" >


                                <TextFieldInput
                                    fullWidth={true}
                                    style={{
                                        boxShadow: "inset 0px 4px 8px rgba(0, 0, 0, 0.05)",
                                    }}
                                    textnewclass={`bg-gn-white-10 rounded-[8px] w-[100%]`}
                                    placeholder={`Enter Email`}
                                    type={`text`}
                                    textinputname={`email`}
                                    id={`email`}
                                    onChange={props.loginWithPasswordForm.handleChange}
                                    onBlur={props.loginWithPasswordForm.handleBlur}
                                    value={props.loginWithPasswordForm.values.email}
                                    error={
                                        props.loginWithPasswordForm.touched.email &&
                                        Boolean(props.loginWithPasswordForm.errors.email)
                                    }
                                    errText={
                                        props.loginWithPasswordForm.touched.email &&
                                        props.loginWithPasswordForm.errors.email
                                    }
                                    clickEnter={props.loginWithPasswordForm.handleSubmit}
                                    disabled={getLocalStorageData("email") ? true : false}
                                />

                                <PasswordFieldInput
                                    style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                                    textnewclass={`bg-gn-white-10 !border !border-solid !border-[#f5f5f5] !rounded-lg `}
                                    fullwidthState={true}
                                    placeholder="Password"
                                    passwordinputText="password"
                                    id="password"
                                    onChange={props?.loginWithPasswordForm?.handleChange}
                                    onBlur={props?.loginWithPasswordForm?.handleBlur}
                                    value={props?.loginWithPasswordForm?.values.password}
                                    error={
                                        props?.loginWithPasswordForm?.touched.password &&
                                        Boolean(props?.loginWithPasswordForm?.errors.password)
                                    }
                                    helperText={
                                        props?.loginWithPasswordForm?.touched.password &&
                                        props?.loginWithPasswordForm?.errors.password
                                    }
                                    clickEnter={props?.loginWithPasswordForm?.handleSubmit}
                                />
                            </div>

                            <ButtonFieldInput
                                type="submit"
                                name="Continue"
                                loading={props.loader}
                                buttonextracls={`w-full !bg-[#2C71F6]/[0.9] !hover:bg-[#2C71F6] duration-300 transition-colors !text-white !py-3 rounded-lg font-[RobotoSemiBold]`}
                            />
                            

                        </form>
                        <div className='w-full flex flex-col gap-2 md:gap-4 mt-2 md:mt-4'>
                            <div className='w-full flex items-center justify-between gap-5 text-[#b8b8b8]'>
                                <div className='w-[40%] h-[2px] rounded-full bg-[#D8D8DA]'></div>
                                <h1 className='text-[RobotoSemiBold] text-[18px]'>or</h1>
                                <div className='w-[40%] h-[2px] rounded-full bg-[#D8D8DA]'></div>
                            </div>

                            <ButtonFieldInput
                                type="button"
                                name="Continue with Unique Code"
                                handleButton={props.continueWithUniqueCode}
                                buttonextracls={`w-full !text-[#2C71F6] !bg-transparent !hover:bg-[#ebebeb] !hover:border-[#ebebeb] duration-500`}
                                style={{ border: "2px solid #ebebeb" }}
                            />
                        </div>
                    </LoginCard>

                </DialogContent>
            </Dialog>
        </div>
    );
};

export default PasswordLoginModal;
