import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Divider } from '@mui/material';
// import { useRouter } from 'next/router';
import { useNavigate } from 'react-router-dom'


export default function TokenExpiryModal(props: any) {
  const { isModalOpen, setIsModalOpen } = props;

  // if(!isModalOpen)  document.body.style.overflow = "hidden";
  // else  document.body.style.overflow = "auto";
  // const navigate = useNavigate()

  const handleClick = () => {
    document.body.style.overflow = "auto";
    setIsModalOpen(false)
    localStorage.clear();
    // navigate("/login");
  }

  return (
    <div className='hs-overlay hs-overlay-backdrop-open:bg-blue-950/90 backdrop-blur-md  w-full h-full fixed top-0 start-0 z-[60] overflow-hidden pointer-events-none"'>
      <Box sx={{ position: 'absolute' as 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, height: 250, boxShadow: 24, padding: 4, backgroundColor: "white", borderRadius: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", border: "none", gap: 1 }}>
        <div className={`flex flex-col justify-center items-center`}>
          <WarningAmberIcon className='text-red-600' />
          <h1 className='text-xl font-bold'>Session Expired!</h1>
        </div>
        <Divider sx={{ width: "50px", backgroundColor: "red" }} />

        <p className='text-center text-sm'>
          Your last session got expired. Please login again to continue.
        </p>
        <Divider sx={{ width: "100%" }} />
        <a href={`${process.env?.REACT_APP_PUBLIC_GN_URL}/login`} className={`mt-3 rounded-md px-2 py-2 bg-[#3c72ca] text-[#fff]`} >Go to Login</a>
        {/* <Button variant="contained" sx={{ mt: 1 }} onClick={handleClick}>GOT IT</Button> */}
      </Box>
    </div>
  );
}