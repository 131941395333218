/* eslint-disable react-hooks/rules-of-hooks */
import AddAddress from '../../components/addAddress'
import { useForQuote } from './hooks'
import AddUser from '../../components/addUser';
import QuoteHeader from '../../common/header/quoteHeader/QuoteHeader';
import { useLocation, useNavigate } from 'react-router';
import Invoice from '../../components/invoice';
import AddQuote from '../../components/addQuote';
import NetSupport from '../../components/netSupport';
import Editor from '../canvasEditor';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import PaymentConfrim from '../../components/payment/paymentConfirm/PaymentConfrim';
import Payment from '../../components/payment';
import AddShippingAddr from '../../components/addShippingAddress/AddShippingAddr';
import Header from '../../common/header/Header';
import { useSelector, useDispatch } from 'react-redux';
import { ForCanvasEditor } from '../canvasEditor/Hooks';
import QuoteDetailsWithOffer from '../../components/quoteDetailsWithOffer/QuoteDetailsWithOffer';

const index = () => {
  const {
    addressFormik,
    changeAddress,
    userForm,
    quoteNotification,
    address,
    getUserDetails,
    getSupports,
    addSupports,
    getQuotes,
    userSupport,
    loading,
    count,
    clearAddress,
    getInvoiceDetails,
    addShippingFormik,
    shippingAddressVal,
    shippingAddr,
    handleShippingAddressChange,
    showBillingAsShipping,
    getAddressObject,
    getQuery,
    handleWillCall,
    showWillCall,
    willCallAddress,
    createPayment,
    addressLoading,
    handlePageLoad,
    billingAddFormik,
    billingAddressVal,
    billingAddress,
    quoteDetailsOpen,
    handleQuoteDetailsOpen,
    handleQuoteDetailsClose,
    billingAdLoader,
    getSaleDetails,
    updateOrder,
    getTax,
    continueToInvoice,
    isUpdateLoading,
    // consentCheck,
    // handleConsent,
    // updateConsentCheck
  } = useForQuote();

  const { openSignupModal,
    openSignupForm,
    closeSignupModal,
    magicLinkForm,
    magicFormBtnLoader,
    mailCheckModalOpen,
    netSupportRedirect,
    handlecloseCheckMailModal,
    createMagicLink
  } = ForCanvasEditor()

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const userDetails = useSelector((state: any) => state?.userReducer?.userDetails);

  const renderComponenet = (args: any) => {
    switch (args) {
      case '/':
        return <AddUser userForm={userForm} quoteNotification={quoteNotification}
        />;
      case '/quote/addaddress':
        return <AddAddress addressFormik={addressFormik} address={address} changeAddress={changeAddress} />;
      case '/quote/addquote':
        return <AddQuote />;
      case `/quote/editor`:
        return <Editor getSupports={getSupports} />;
      case '/quote/netsupport':
        return <NetSupport test={'testString'}
          userSupport={userSupport} getSupports={getSupports} addSupports={addSupports} getQuotes={getQuotes}
          quoteDetailsOpen={quoteDetailsOpen} handleQuoteDetailsOpen={handleQuoteDetailsOpen} handleQuoteDetailsClose={handleQuoteDetailsClose} />;
      case '/quote/payment':
        return <Payment
          getInvoiceDetails={getInvoiceDetails}
          createPayment={createPayment}
        />
      case '/quote/paymentConfirm':
        return <PaymentConfrim
          getInvoiceDetails={getInvoiceDetails}
        />
      case '/quote/addshippingaddr':
        return <AddShippingAddr
          billingAdLoader={billingAdLoader}
          count={count}
          getInvoiceDetails={getInvoiceDetails}
          getSupports={getSupports}
          addShippingFormik={addShippingFormik}
          shippingAddressVal={shippingAddressVal}
          shippingAddr={shippingAddr}
          handleShippingAddressChange={handleShippingAddressChange}
          showBillingAsShipping={showBillingAsShipping}
          clearAddress={clearAddress}
          getAddressObject={getAddressObject}
          getQuery={getQuery}
          handleWillCall={handleWillCall}
          showWillCall={showWillCall}
          willCallAddress={willCallAddress}
          addSupports={addSupports}
          addressLoading={addressLoading}
          billingAddFormik={billingAddFormik}
          billingAddressVal={billingAddressVal}
          billingAddress={billingAddress}
          getUserDetails={getUserDetails}
          getSaleDetails={getSaleDetails}
        />
      case '/quote/applyoffer':
        return <QuoteDetailsWithOffer getQuotes={getQuotes}
          getSaleDetails={getSaleDetails}
          getInvoiceDetails={getInvoiceDetails}
          updateOrder={updateOrder}
          getTax={getTax}
          continueToInvoice={continueToInvoice}
          isUpdateLoading={isUpdateLoading}
          // consentCheck={consentCheck}
          // handleConsent={handleConsent}
          // updateConsentCheck={updateConsentCheck}
        />
      case '/quote/invoice':
        return <Invoice getInvoiceDetails={getInvoiceDetails}
          createPayment={createPayment}
          getSupports={getSupports}
          addressLoading={addressLoading}
          openSignupModal={openSignupModal}
          openSignupForm={openSignupForm}
          closeSignupModal={closeSignupModal}
          magicLinkForm={magicLinkForm}
          magicFormBtnLoader={magicFormBtnLoader}
          mailCheckModalOpen={mailCheckModalOpen}
          netSupportRedirect={netSupportRedirect}
          handlecloseCheckMailModal={handlecloseCheckMailModal}
          createMagicLink={createMagicLink}
        />;
      default:
        return null;
    }
  }



  // useEffect(() => {
  //   if (!searchParams.get('quoteId')) {
  //     handlePageLoad()
  //   } else {
  //     if (Object.keys(userDetails) && userDetails?._id && token) {
  //       if (searchParams.get('payment')) {
  //         navigate(`/quote/payment?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&total=${searchParams.get('total')}&format=payment&payment=true&index=${searchParams.get('index') ?? 0}`)
  //       } else if (searchParams.get('paymentConfirm')) {
  //         navigate(`/quote/paymentConfirm?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&total=${searchParams.get('total')}&index=${searchParams.get('index') ?? 0}&format=payment&paymentConfirm=true`)
  //       }
  //     } else if (searchParams.get('token') || !isExpired) {
  //       if (searchParams.get('token')) {
  //         dispatch({ type: "RESET_STATE" });
  //         localStorage.clear();
  //         console.log('else if if 1 >>>> $$');
  //       }
  //       getUserDetails();
  //       console.log("else if 2 >>> $$");
  //     }
  //     else {
  //       // logout();
  //     }
  //   }
  // }, [userDetails])


  return (
    <div>
      <Header />
      <div>
        {loading ?
          <CircularProgress size={18} className="" color="inherit" /> :
          <div className={`flex flex-col`}>
            {((location.pathname?.match('/quote/addshippingaddr')) || location.pathname?.match('quote/applyoffer') || (location.pathname?.match('/quote/paymentConfirm')) || (location.pathname?.match('/quote/editor'))) ? null : <QuoteHeader />}
            {renderComponenet(location.pathname)}
          </div>
        }
      </div>
    </div>
  )
}

export default index