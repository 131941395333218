import React from 'react'
import { NavLink } from 'react-router-dom'
import { navigateToGn } from '../../utils/navigateToGn'

const LoginCard = ({ title, description, children, postContent}: any) => {
  return (
    <>
       <div className='w-full flex justify-center'>
          <div className='w-[95%] sm:w-[500px] md:w-[550px] h-fit bg-white mt-10 p-5 py-8 md:p-10 text-[#202020] md:min-h-[600px]'>

            <h1 className='text-center text-[18px] md:text-[28px] font-[RobotoBold] mb-2'>{title}</h1>
            <p className='px-6 text-center text-[12px] md:text-[14px] font-[RobotoMedium] text-[#6B7D98]'>{description}</p>

            <div className='my-6'>{children}</div>

            <h1 className='my-6 w-full font-[RobotoSemiBold] text-[12px] md:text-[15px] bg-[#EFF6FF] p-3 py-4 rounded-xl text-center text-[#6B7D98]'>Creating a Solar Nets account signifies
            your acceptance of our <NavLink to={navigateToGn('/policy')}><span className='text-[#1D73E3] font-[RobotoSemiBold]'>Privacy Policy</span></NavLink> and  <NavLink to={navigateToGn('/termsandcondition')}><span className='text-[#1D73E3] font-[RobotoSemiBold]'>Terms</span></NavLink>.</h1>

            <div className='my-4'>{postContent}</div>

          </div>
        </div>
    </>
  )
}

export default LoginCard
