import React, { useState } from 'react'
import './index.css'
// import { sideNavData } from './sideNavData'
import MuiAppBar from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { fetchHelpContent } from '../../redux/helpModalSlice';
import { useDispatch } from 'react-redux';
import help_icon from "../../Images/help_icon.svg";
import { ForAssemblyEditor } from '../../container/netAsemblyEditor/Hooks';



const SideNav = (props: any) => {

    const { selectedStep, setselectedStep, pannelData } = props

    const dispatch = useDispatch()
    const { sideNavData } = props

    const handelStepClick = (item: any, idx: number) => {
        props.handleClick(item)
        dispatch(fetchHelpContent({ keywordItem: "step0_net_editor", type: "HEADER_HELP_CONTENT" }))
        setselectedStep(idx)
    }


    return (
        <div className='h-full !p-0 z-20 flex flex-col items-center'>
            <div className={`h-full bg-[#dbdbdb] `}>
                {
                    sideNavData?.map((item: any, idx: number) => {
                        return (
                            <div
                                className={`cursor-pointer w-full h-fit flex justify-center items-center ${item?.isAsemblyEditor ? " p-3" : "px-4 py-2"}
                            ${props.pannelType === `Solar Panel` ? `roundSolarNet1` : props.pannelType === `Solar Net` ? `roundedPanel roundedSupport` : props.pannelType === `Support` ? `roundSolarNet2` : ``}
                             ${!item?.isAsemblyEditor && selectedStep === idx ? `bg-[#fff]` : `bg-transprent`} w-full relative drive-item-${idx + 1} flex flex-col items-start justify-center `}
                                key={idx}
                                onClick={() => handelStepClick(item, idx)}>

                                {!item?.isAsemblyEditor &&
                                    <div className='w-full flex items-center justify-between mb-2'>
                                        <p className={`text-red-600 text-[13px] font-medium`}>Step {idx + 1}</p>
                                        <img onClick={(e) => { e.stopPropagation(); dispatch(fetchHelpContent({ keywordItem: item?.keyword, type: null })) }}
                                            src={help_icon} alt="help_icon" className={`bg-gray-300 hover:bg-gray-500 rounded-full text-sm w-[18px] h-[18px]`} />
                                    </div>
                                }

                                <div className={`flex flex-col justify-center items-center w-[100%] 
                                ${item?.isAsemblyEditor ? "p-1.5 rounded-xl" : ""}
                                ${item?.isAsemblyEditor && (pannelData?.type === item.type) ? `bg-[#fff]/[0.7] backdrop-blur-md` : `bg-transprent`}
                                    `}>
                                    <div className={`w-[50px] h-[50px] flex justify-center items-center`}>
                                        <img src={item?.img} alt='img'
                                            className={`object-contain ${item?.isAsemblyEditor ? `${item?.title === 'Add Ropes' ? "w-10 h-10" : "w-12 h-12"}` : "w-full h-full"}`}
                                        />
                                    </div>
                                    <p className={`text-center font-regular mt-[2px] whitespace-nowrap ${item?.isAsemblyEditor ? "text-[12px]" : "text-[13px]"}`}>{item?.title}</p>
                                </div>


                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SideNav